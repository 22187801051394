import {ReactComponent as Logo} from "./logo.svg";
import c from "./app.module.css";

import Pim from "./pim";
import Lib from "./lib";

function App() {
  return (
    <>
      <h1 className={c.header}>
        <a href="/logo.html">
          <Logo className={c.logo} />
        </a>
        Pimalaya
      </h1>

      <p>
        <a href="https://sr.ht/~soywod/pimalaya/">Pimalaya</a> is an ambitious project that aims to improve open-source
        tools related to Personal Information Management (<Pim wiki />) which includes emails, contacts, calendars,
        tasks and more.
      </p>

      <p>
        <strong>The first aim</strong> is to provide Rust libraries dedicated to the <Pim /> domain. They serve as a
        basis for all sorts of top-level applications, which prevents developers to reinvent the wheel.
      </p>

      <table>
        <thead>
          <tr>
            <th>Library name</th>
            <th>Source code</th>
            <th>Registry</th>
            <th>Documentation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td title="List mailboxes, search envelopes, read/write/send messages…">email-lib</td>
            <td>
              <a href="https://git.sr.ht/~soywod/pimalaya/tree/master/item/email/README.md">sourcehut</a>
            </td>
            <td>
              <a href="https://crates.io/crates/email-lib">crates.io</a>
            </td>
            <td>
              <a href="https://docs.rs/email-lib/latest/email/">docs.rs</a>
            </td>
          </tr>
          <tr>
            <td title="Read/write messages using the MIME Meta Language.">mml-lib</td>
            <td>
              <a href="https://git.sr.ht/~soywod/pimalaya/tree/master/item/mml/README.md">sourcehut</a>
            </td>
            <td>
              <a href="https://crates.io/crates/mml-lib">crates.io</a>
            </td>
            <td>
              <a href="https://docs.rs/mml-lib/latest/mml/">docs.rs</a>
            </td>
          </tr>
          <tr>
            <td title="Manage personal time using customizable client/server timers.">time-lib</td>
            <td>
              <a href="https://git.sr.ht/~soywod/pimalaya/tree/master/item/time/README.md">sourcehut</a>
            </td>
            <td>
              <a href="https://crates.io/crates/time-lib">crates.io</a>
            </td>
            <td>
              <a href="https://docs.rs/time-lib/latest/time/">docs.rs</a>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>The second aim</strong> is provide quality house-made applications built on top of these libraries,
        gathered into projects.
      </p>

      <table>
        <thead>
          <tr>
            <th>Project name</th>
            <th>Interface</th>
            <th>Source code</th>
            <th>Documentation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="4" title="Project to manage emails">
              <strong>Himalaya</strong>,<br />
              <em>email client</em>
            </td>
            <td>CLI</td>
            <td>
              <a href="https://github.com/soywod/himalaya">github</a>
            </td>
            <td>
              <a href="/himalaya/cli/latest/">pimalaya</a>
            </td>
          </tr>
          <tr>
            <td>Vim plugin</td>
            <td>
              <a href="https://git.sr.ht/~soywod/himalaya-vim">sourcehut</a>
            </td>
            <td>
              <a href="https://git.sr.ht/~soywod/himalaya-vim">sourcehut</a>
            </td>
          </tr>
          <tr>
            <td>Emacs plugin</td>
            <td>
              <a href="https://github.com/dantecatalfamo/himalaya-emacs">github</a>
            </td>
            <td>
              <a href="https://github.com/dantecatalfamo/himalaya-emacs">github</a>
            </td>
          </tr>
          <tr>
            <td>Raycast extension</td>
            <td>
              <a href="https://github.com/raycast/extensions/tree/main/extensions/himalaya">github</a>
            </td>
            <td>
              <a href="https://www.raycast.com/jns/himalaya">raycast</a>
            </td>
          </tr>
          <tr>
            <td title="Project to synchronize and backup emails">
              <strong>Neverest</strong>,<br />
              <em>email synchronizer</em>
            </td>
            <td>CLI</td>
            <td>
              <a href="https://github.com/soywod/neverest">github</a>
            </td>
            <td>
              <a href="/neverest/cli/latest/">pimalaya</a>
            </td>
          </tr>
          <tr>
            <td rowspan="2" title="Project to deal with email message templates">
              <strong>MML</strong>,<br />
              <em>email message language</em>
            </td>
            <td>CLI</td>
            <td>
              <a href="https://github.com/soywod/mml">github</a>
            </td>
            <td>
              <a href="/mml/cli/latest/">pimalaya</a>
            </td>
          </tr>
          <tr>
            <td>Vim plugin</td>
            <td>
              <a href="https://git.sr.ht/~soywod/mml-vim">sourcehut</a>
            </td>
            <td>
              <a href="https://git.sr.ht/~soywod/mml-vim">sourcehut</a>
            </td>
          </tr>
          <tr>
            <td rowspan="2" title="Project to manage personal timer">
              <strong>Comodoro</strong>,<br />
              <em>timer client</em>
            </td>
            <td>CLI</td>
            <td>
              <a href="https://github.com/soywod/comodoro">github</a>
            </td>
            <td>
              <a href="/comodoro/cli/latest/">pimalaya</a>
            </td>
          </tr>
          <tr>
            <td>Raycast extension</td>
            <td>
              <a href="https://github.com/raycast/extensions/tree/main/extensions/comodoro">github</a>
            </td>
            <td>
              <a href="https://www.raycast.com/jns/comodoro">raycast</a>
            </td>
          </tr>
        </tbody>
      </table>

      <details>
        <summary>
          <em>More about Himalaya</em>
        </summary>
        <p>
          Himalaya was the first project of Pimalaya. It strives to be everything you need to manage emails. Few
          interfaces have been built at the top of <Lib name="email" /> and <Lib name="mml" />:
        </p>
        <ul>
          <li>
            <a href="/himalaya/cli/latest/">CLI</a>
          </li>
          <li>REPL (coming soon)</li>
          <li>GUI (planned)</li>
          <li>
            <a href="/himalaya/vim/">Vim</a> plugin
          </li>
          <li>
            <a href="/himalaya/emacs/">Emacs</a> plugin
          </li>
          <li>
            <a href="https://www.raycast.com/jns/himalaya">Raycast</a> extension
          </li>
        </ul>
      </details>

      <details>
        <summary>
          <em>More about Neverest</em>
        </summary>
        <p>
          Neverest is the project dedicated to email synchronization and backup. Its scope is more narrow than
          Himalaya's one, see the <a href="/neverest/cli/latest/faq.html">FAQ</a> to understand the difference. Only a
          CLI has been built so far, at the top of <Lib name="email" />:
        </p>
        <ul>
          <li>
            <a href="/neverest/cli/latest/">CLI</a>
          </li>
        </ul>
      </details>

      <details>
        <summary>
          <em>More about MML</em>
        </summary>
        <p>
          This small project gathers everything related to the Emacs MIME Message Meta Language, as known as{" "}
          <a href="https://www.gnu.org/software/emacs/manual/html_node/emacs-mime/MML-Definition.html">MML</a>:
        </p>
        <blockquote cite="https://www.gnu.org/software/emacs/manual/html_node/emacs-mime/Composing.html">
          Creating a MIME message is boring and non-trivial. Therefore, a library called mml has been defined that
          parses a language called MML (MIME Meta Language) and generates MIME messages.
        </blockquote>
        <p>The two main use cases of the project are:</p>
        <ol>
          <li>
            You want to write a MIME message from scratch or you want to edit an existing one (reply, forward): they can
            be written in MML then compiled into MIME messages as defined in the{" "}
            <a href="https://www.rfc-editor.org/rfc/rfc2045">RFC 2045</a>.
          </li>
          <li>
            You want to read a MIME message: they can be interpreted as MML messages, which are way more human-readable
            than MIME messages.
          </li>
        </ol>
        For example, the given MML message:
        <pre>
          From: alice@localhost
          <br />
          To: bob@localhost
          <br />
          Subject: MML examples
          <br />
          <br />
          This is a plain text part.
          <br />
          <br />
          &lt;#part type=text/html&gt;
          <br />
          &lt;h1&gt;This is a HTML part.&lt;/h1&gt;
          <br />
          &lt;#/part&gt;
          <br />
          <br />
          &lt;#part filename=./examples/attachment.png description=&quot;This is an attachment.&quot;&gt;&lt;#/part&gt;
          <br />
        </pre>
        compiles into the following MIME message:
        <pre>
          MIME-Version: 1.0
          <br />
          From: &lt;alice@localhost&gt;
          <br />
          To: &lt;bob@localhost&gt;
          <br />
          Subject: MML examples
          <br />
          Message-ID: &lt;17886a741feef4a2.f9706245cd3a3f97.3b41d60ef9e2fbfb@soywod&gt;
          <br />
          Date: Tue, 26 Sep 2023 09:58:26 +0000
          <br />
          Content-Type: multipart/mixed; <br />
          boundary=&quot;17886a741fef2cb2_97a7dbff4c84bbac_3b41d60ef9e2fbfb&quot;
          <br />
          <br />
          <br />
          --17886a741fef2cb2_97a7dbff4c84bbac_3b41d60ef9e2fbfb
          <br />
          Content-Type: text/plain; charset=&quot;utf-8&quot;
          <br />
          Content-Transfer-Encoding: 7bit
          <br />
          <br />
          This is a plain text part.
          <br />
          <br />
          <br />
          --17886a741fef2cb2_97a7dbff4c84bbac_3b41d60ef9e2fbfb
          <br />
          Content-Type: text/html; charset=&quot;utf-8&quot;
          <br />
          Content-Transfer-Encoding: 7bit
          <br />
          <br />
          &lt;h1&gt;This is a HTML part.&lt;/h1&gt;
          <br />
          <br />
          Content-Type: application/octet-stream
          <br />
          Content-Disposition: attachment; filename=&quot;attachment.png&quot;
          <br />
          Content-Transfer-Encoding: base64
          <br />
          <br />
          iVBORw0KGgo…
          <br />
          <br />
          --17886a741fef2cb2_97a7dbff4c84bbac_3b41d60ef9e2fbfb--
          <br />
        </pre>
        <p>
          Few interfaces have been built at the top of <Lib name="mml" />:
        </p>
        <ul>
          <li>
            <a href="/mml/cli/latest/">CLI</a>
          </li>
          <li>
            <a href="/mml/vim/latest/">Vim</a> plugin
          </li>
        </ul>
      </details>

      <details>
        <summary>
          <em>More about Comodoro</em>
        </summary>
        <p>
          Comodoro strives to be everything you need to manage personal time using timers. The main use case is to track
          your worktime. A good example is the{" "}
          <a href="https://en.wikipedia.org/wiki/Pomodoro_Technique">Pomodoro Technique</a>. Few interfaces have been
          built at the top of <Lib name="time" />:
        </p>
        <ul>
          <li>
            <a href="/comodoro/cli/latest/">CLI</a>
          </li>
          <li>
            <a href="https://www.raycast.com/jns/comodoro">Raycast</a> extension
          </li>
        </ul>
      </details>

      <p>
        --&nbsp;
        <br />
        Written with love,
        <br />
        The Pimalaya Team
      </p>
    </>
  );
}

export default App;
